<template>
  <div class="mt-2" ref="selfContainer">
    <v-row>
      <v-col cols="3" class="align-self-center mt-2">
        <h2 class="font-weight-regular text--primary">История операций</h2>
      </v-col>
      <v-col offset="3" cols="3" class="hide-on-print">
        <v-menu v-model="date1menu" :close-on-content-click="false"
                transition="scale-transition" offset-y max-width="290px" min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="fromDateFormatted"
                          label="с" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.reg_date_after" @input="dateChanged"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3" class="hide-on-print">
        <v-menu v-model="date2menu" :close-on-content-click="false"
                transition="scale-transition" offset-y max-width="290px" min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="toDateFormatted"
                          label="по" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.reg_date_before" @input="dateChanged"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row v-if="!!fetchingHistory">
      <v-col class="text-center my-3">
        <v-progress-circular width="2" indeterminate></v-progress-circular>
      </v-col>
    </v-row>
    <v-row v-else class="font-weight-bold blue-grey lighten-5 pa-3 mb-0 subtitle-1">
        <v-col cols="3" class="mb-2">
          <div class="success--text text--darken-3">Дебет: {{meta.debet_uzs_summa || 0 | money}}</div>
          <div class="error--text text--darken-2">Кредит: {{meta.credit_uzs_summa || 0 | money }}</div>
          <div>Итог: {{(meta.debet_uzs_summa || 0) - (meta.credit_uzs_summa || 0) | money }}</div>
        </v-col>
        <v-col cols="3" class="mb-2">
          <div class="success--text text--darken-3">Дебет: {{meta.debet_usd_summa || 0 | money('usd')}}</div>
          <div class="error--text text--darken-2">Кредит: {{meta.credit_usd_summa || 0 | money('usd')}}</div>
          <div>Итог: {{(meta.debet_usd_summa || 0) - (meta.credit_usd_summa || 0) | money('usd') }}</div>
        </v-col>
        <v-col cols="3" class="mb-2">
          <div class="success--text text--darken-3">Всего дебет: {{meta.debet_uzs_total_summa || 0 | money}}</div>
          <div class="error--text text--darken-2">Всего кредит: {{meta.credit_uzs_total_summa || 0 | money}}</div>
          <div>Итог: {{(meta.debet_uzs_total_summa || 0) - (meta.credit_uzs_total_summa || 0) | money }}</div>
        </v-col>
        <v-col cols="3" class="text-right d-flex align-top justify-end">
          <span :class="meta.difference > 0 ? 'success--text text--darken-3' : 'error--text text--darken-2'">
            <span v-if="meta.difference < 0">Мы должны:</span>
            <span v-else>Нам должны:</span>
            {{ meta.difference|money }}
          </span>
        </v-col>
    </v-row>
    <v-data-table :headers="nonSortableHeaders" :loading="!!fetchingHistory"
                  :items="histories" :items-per-page="filter.limit"
                  hide-default-footer disable-items-per-page fixed-header
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{ filter.limit * (filter.page - 1) + index + 1 }}</td>
          <td>
            {{ HistoryService.getTitle(item) }}
          </td>
          <td class="text-right">
            {{ item.total|money(item.currency) }}
          </td>
          <td>{{ item.document_reg_number }}</td>
          <td>{{ item.reg_date|date }}</td>
          <td class="text-right">
            <span v-if="item.type === 'debet'">{{ item.total_uzs|money }}</span>
          </td>
          <td class="text-right">
            <span v-if="item.type === 'credit'">{{ item.total_uzs|money }}</span>
          </td>
          <td class="text-right hide-on-print">
            <v-btn v-if="canShowDetails(item)" color="primary" dark small text target="_blank"
                   @click="showDetails(item)"
            ><v-icon small>mdi-eye</v-icon></v-btn>
            <v-btn v-if="canSeeDetails && item.calculation_type === 'balance'"
                   class="ml-2" color="error" dark text icon small depressed
                   @click="askRemoveBalanceItem(item)"
                   :loading="deletingBalance[item.id]"
              ><v-icon small>mdi-delete</v-icon></v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="detailDialog" width="70vw">
      <component v-if="detailDialog" :is="detailComponent" compact
                 @dialog:close="closeDetails"
      ></component>
    </v-dialog>
    <div class="hide-on-print text-center my-3">
      <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages" :extra-page-size="{id: 1000, text: 'Все'}"></pagination>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {SERVER_DATE_FORMAT, USER_DATE_FORMAT} from '@/filters'
import {mapState, mapGetters} from 'vuex'

import {HistoryService} from '@/services/counteragent'
import {hasFilter} from '@/mixins'

import ConsignmentDetails from '@/modules/consignment/details'
import ConsignmentReturnDetails from '@/modules/consignment/return/details'
import PaymentDetails from '@/modules/payment/details'
import TransferDetails from '@/modules/transfer/details'

export default {
  name: 'counteragent-history',

  components: {
    ConsignmentDetails,
    ConsignmentReturnDetails,
    PaymentDetails,
    TransferDetails,
  },

  props: {
    counteragentId: {
      required: true,
    }
  },

  mixins: [hasFilter],

  customFilters: {
    reg_date_before: moment().format(SERVER_DATE_FORMAT),
    reg_date_after: moment().subtract(1, 'year').startOf('year').format(SERVER_DATE_FORMAT),
  },

  data() {
    return {
      detailComponent: null,
      detailComponentStore: null,
      detailDialog: false,

      HistoryService,

      date1menu: false,
      date2menu: false,

      headers: [
        {text: '#', align: 'center', width: '70px'},
        {text: 'Операция'},
        {text: 'Сумма', align: 'right'},
        {text: 'Рег номер', width: '120px'},
        {text: 'Дата', width: '120px'},
        {text: 'Дебет', align: 'right', width: '200px'},
        {text: 'Кредит', align: 'right', width: '200px'},
        {text: 'Действие', align: 'right', width: '140px', class: 'hide-on-print'},
      ]
    }
  },

  computed: {
    ...mapState('Counteragent', ['history', 'fetchingHistory']),
    ...mapGetters('Contract', ['getContract']),
    ...mapState('StartingPoint', {
      deletingBalance: state => state.deleting,
    }),

    data() {
      return this.history
    },

    histories() {
      return this.history?.results || []
    },

    meta() {
      return this.history?.type_total || {}
    },

    fromDateFormatted() {
      return moment(this.filter.reg_date_after).format(USER_DATE_FORMAT)
    },

    toDateFormatted() {
      return moment(this.filter.reg_date_before).format(USER_DATE_FORMAT)
    },

    canSeeDetails() {
      return this.userService.hasRole('admin')
    },
  },

  methods: {
    fetch() {
      return this.$store.dispatch('Counteragent/fetchHistory', {
        ...this.compiledFilters,
        counteragent: this.counteragentId,
      })
    },

    askRemoveBalanceItem(item) {
      if (confirm(`Вы уверены удалить ${HistoryService.getTitle(item)}`)) {
        this.removeBalanceItem(item)
            .finally(this.fetch)
      }
    },

    removeBalanceItem(item) {
      return this.$store.dispatch('StartingPoint/remove', item.id)
    },

    dateChanged() {
      this.date1menu = false
      this.date2menu = false
      this.$nextTick(this.fetch)
    },

    canShowDetails(item) {
      return ['consignmentdoc', 'priyomkadocument', 'payment', 'returnproductdoc'].indexOf(item.source_name) > -1
    },

    showDetails(item) {
      let action = 'show'
      switch (item.source_name) {
        case 'consignmentdoc':
          this.detailComponent = 'ConsignmentDetails'
          this.detailComponentStore = 'Consignment'
          break;
        case 'priyomkadocument':
          this.detailComponent = 'TransferDetails'
          this.detailComponentStore = 'Transfer'
          break;
        case 'returnproductdoc':
          this.detailComponent = 'ConsignmentReturnDetails'
          this.detailComponentStore = 'Consignment'
          action = 'showReturn'
          break;
        case 'payment':
          this.detailComponent = 'PaymentDetails'
          this.detailComponentStore = 'Payment'
          break;
        default:
          return;
      }

      this.$store.dispatch(`${this.detailComponentStore}/${action}`, item.source_object.id)
      this.detailDialog = true
    },

    closeDetails() {
      this.detailDialog = false
      if (this.detailComponent === 'ConsignmentReturnDetails') {
        this.$store.commit('Consignment/setReturn', {})
      } else {
        this.$store.commit(`${this.detailComponentStore}/setModel`, {})
      }
      this.detailComponent = null
    },
  },

  created() {
    this.fetch()

    this.$eventBus.$on('counteragent:refresh-history', () => {
      this.fetch()
    })
  },

  beforeDestroy() {
    this.$eventBus.$off('counteragent:refresh-history')
  }
}
</script>
