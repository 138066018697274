<template>
  <span>
    <v-btn :loading="loading" :outlined="!icon" :rounded="!icon" :icon="icon" :class="icon ? '' : 'mr-3'" color="primary" @click="print">
      <v-icon :small="icon" :left="!icon">mdi-printer</v-icon> {{icon ? '' : 'Накладная'}}
    </v-btn>

    <div style="display: none;" ref="doc">
      <div style="text-align: center; text-transform: uppercase; font-weight: bold;">Накладная счет-фактура
        №{{ consignment.reg_number }}
      </div>
      <div style="text-align: right; padding-right: 20%;">
        <div>от {{ consignment.reg_date|stringDateTime }}</div>
        <div>к договору ____ от __________ г.</div>
      </div>
      <div style="margin-top: 20px; margin-left: 20px; margin-right: 20px;">
        <table style="width: 100%;">
          <tr>
            <td style="border-bottom: 1px solid #eee; width: 50%; font-weight: bold;">Поставщик OLIS OSMON XKK</td>
            <td style="border-bottom: 1px solid #eee; text-align: right; width: 120px; font-weight: bold;">Покупатель</td>
            <td style="border-bottom: 1px solid #eee; padding-left: 10px; text-transform: uppercase; font-weight: bold;">
              {{ counteragent.full_name }}
            </td>
          </tr>
          <tr>
            <td style="border-bottom: 1px solid #eee; width: 50%;">Адрес Термиз тумани Бунёдкор махаласи</td>
            <td style="border-bottom: 1px solid #eee; text-align: right; width: 120px">Адрес</td>
            <td style="border-bottom: 1px solid #eee; padding-left: 10px; ">{{ counteragent.legal_address }}</td>
          </tr>
          <tr>
            <td style="border-bottom: 1px solid #eee; width: 50%;">Телефон 99-425-14-14</td>
            <td style="border-bottom: 1px solid #eee; text-align: right; width: 120px">Телефон</td>
            <td style="border-bottom: 1px solid #eee; padding-left: 10px; ">{{ counteragent.phone|phone }}</td>
          </tr>
          <tr>
            <td style="border-bottom: 1px solid #eee; width: 50%;">Р/сч 20208000104831822001</td>
            <td style="border-bottom: 1px solid #eee; text-align: right; width: 120px">Р/сч</td>
            <td style="border-bottom: 1px solid #eee; padding-left: 10px; ">{{ counteragent.bank_account }}</td>
          </tr>
          <tr>
            <td style="border-bottom: 1px solid #eee; width: 50%;">В Кишлоккурилишбанк Тошкент Амалиёт булим</td>
            <td style="border-bottom: 1px solid #eee; text-align: right; width: 120px">B</td>
            <td style="border-bottom: 1px solid #eee; padding-left: 10px; "></td>
          </tr>
          <tr>
            <td style="border-bottom: 1px solid #eee; width: 50%;">город Ташкент</td>
            <td style="border-bottom: 1px solid #eee; text-align: right; width: 120px">город</td>
            <td style="border-bottom: 1px solid #eee; padding-left: 10px; "></td>
          </tr>
          <tr>
            <td style="border-bottom: 1px solid #eee; width: 50%;">MФO 01037</td>
            <td style="border-bottom: 1px solid #eee; text-align: right; width: 120px">МФО</td>
            <td style="border-bottom: 1px solid #eee; padding-left: 10px; ">{{ counteragent.bank_code }}</td>
          </tr>
          <tr>
            <td style="border-bottom: 1px solid #eee; width: 50%;">ИНН 301128574</td>
            <td style="border-bottom: 1px solid #eee; text-align: right; width: 120px">ИНН</td>
            <td style="border-bottom: 1px solid #eee; padding-left: 10px; ">{{ counteragent.inn }}</td>
          </tr>
        </table>
      </div>

      <div style="margin-top: 20px; padding-left: 20px; padding-right: 20px;">
        <table style="width: 100%;  border-collapse: collapse;">
          <thead>
          <tr style="border-collapse: collapse">
            <th
              style="background: #ccc; border: 1px solid #aaa; padding: 5px 10px; font-weight: bold; text-align: center;">
              №
            </th>
            <th style="background: #ccc; border: 1px solid #aaa; padding: 5px 10px; font-weight: bold; text-align: left;">
              Наименование товаров (работ, услуг)
            </th>
            <th
              style="background: #ccc; border: 1px solid #aaa; padding: 5px 10px; font-weight: bold; text-align: center;">
              Ед. изм
            </th>
            <th
              style="background: #ccc; border: 1px solid #aaa; padding: 5px 10px; font-weight: bold; text-align: center;">
              Кол-во
            </th>
            <th
              style="background: #ccc; border: 1px solid #aaa; padding: 5px 10px; font-weight: bold; text-align: center;">
              Цена
            </th>
            <th
              style="background: #ccc; border: 1px solid #aaa; padding: 5px 10px; font-weight: bold; text-align: right;">
              Стоимость поставки
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in consignment.consignments_elements">
            <td style="border: 1px solid #aaa; padding: 5px 10px; text-align: center;">{{ index + 1 }}</td>
            <td style="border: 1px solid #aaa; padding: 5px 10px; ">{{ item.product_type_name }}</td>
            <td style="border: 1px solid #aaa; padding: 5px 10px; text-align: center;">
              {{(getMeasurement(item.product_type_measurement) || {}).name || ''}}
            </td>
            <td style="border: 1px solid #aaa; padding: 5px 10px; text-align: center;">{{ item.amount }}</td>
            <td style="border: 1px solid #aaa; padding: 5px 10px; text-align: center;">{{
                item.price|money(consignment.currency)
              }}
            </td>
            <td style="border: 1px solid #aaa; padding: 5px 10px; text-align: right;">
              {{ item.total|money(consignment.currency, true) }}
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th colspan="5" style="padding: 5px; text-align: right;">Итого</th>
            <th style="padding: 5px; text-align: right;">{{ total|money(consignment.currency) }}</th>
          </tr>
          </tfoot>
        </table>
      </div>

      <div style="margin-top: 20px; margin-left: 20px; margin-right: 20px;">
        <table style="width: 100%;">
          <tr>
            <td style=" width: 50%; font-weight: bold;">Руководитель</td>
            <td style=" text-align: right; width: 120px; font-weight: bold;">Товар получиль</td>
            <td></td>
          </tr>
          <tr>
            <td colspan="3" style="font-size: 10pt;">м.п.</td>
          </tr>
          <tr>
            <td style=" width: 50%; font-weight: bold;">Главный бухгалтер</td>
            <td style=" text-align: right; width: 120px; font-weight: bold;">Автомашина №</td>
            <td style=" padding-left: 10px; font-weight: bold;">{{ consignment.non_company_transport }}</td>
          </tr>
          <tr>
            <td style="font-weight: bold; width: 50%;">Товар отпустил</td>
            <td style="font-weight: bold; text-align: right; width: 120px">через</td>
            <td style="font-weight: bold; padding-left: 10px; ">{{ consignment.non_staff_driver }}</td>
          </tr>
          <tr>
            <td style="width: 50%; text-align: right; padding-right: 20px; font-size: 10pt;">
              <span style="display: inline-block; border-top: 1px solid #aaa;">(подпись ответственного лица от подставщика)</span>
            </td>
            <td></td>
            <td style="text-align: right; padding-right: 20px; font-size: 10pt;">
              <span style="display: inline-block; border-top: 1px solid #aaa;">(Ф.И.О. получателя)</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </span>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import hasCurrency from '@/mixins/hasCurrency'

export default {
  name: 'consignment-note',

  mixins: [hasCurrency],

  currencyModel: 'consignment',

  props: {
    icon: {
      type: Boolean,
      default: false,
    },
    consignment: {
      required: true,
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapState('Counteragent', {
      counteragent: state => state.model,
    }),
    ...mapGetters('Product', ['getProduct']),
    ...mapGetters('Measurement', ['getMeasurement']),

    total() {
      return (this.consignment.consignments_elements || [])
        .reduce((total, ti) => total + ti.total, 0)
        .toFixed(this.pricePrecision)
    }
  },

  methods: {
    loadData() {
      let id = typeof this.consignment.counteragent === 'object' ? this.consignment.counteragent.id : this.consignment.counteragent

      return [
        this.$store.dispatch('Counteragent/show', id),
      ]
    },

    print() {
      this.loading = true
      Promise.all(this.loadData()).then(() => {
          this.loading = false
          this.$nextTick(() => {
            let wnd = window.open()
            wnd.document.write(this.$refs.doc.innerHTML);
            wnd.print();
          })
      }).finally(() => this.loading = false)
    },
  },
}
</script>
