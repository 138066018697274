import Vue from 'vue'
import ut from '@/utils'

import {Resource} from 'vue-resource'

const CounteragentTypes = [
  {id: 'legal', name: 'Юрлицо'},
  {id: 'physical', name: 'Физлицо'},
  {id: 'individual', name: 'Индивидуальный'},
]
const ClientTypes = [
  {id: 'klient', name: 'Клиент'},
  {id: 'gasoil', name: 'АЗС'},
]

const Counteragent = {
  id: null,
  name: '',
  full_name: '',
  counteragent_type: '',
  client_type: ClientTypes[0].id,
  phone: '',
  phone_2: '',
  phone_3: '',
  phone_4: '',
  email: '',
  address: 'Surxondaryo viloyati ',
  comment_on_address: '',
  comment: '',
  code: '',
  inn: '',
  bank_code: '',
  bank_account: '',
  agent: '',
  legal_address: 'Surxondaryo viloyati ',
  comment_on_legal_address: '',
}

const CounteragentResource = new Resource('/', {}, {
  list: {
    method: 'GET',
    url: 'counteragent/list/',
  },
  details: {
    method: 'GET',
    url: 'counteragent/detail/{id}/',
  },
  history: {
    method: 'GET',
    url: 'counteragent/calculation/list/',
  },
  create: {
    method: 'POST',
    url: 'counteragent/create/',
  },
  update: {
    method: 'PUT',
    url: 'counteragent/update/{id}/',
  },
  remove: {
    method: 'DELETE',
    url: 'counteragent/delete/{id}/',
  },
})

export default {
  namespaced: true,

  state: {
    CounteragentTypes,
    ClientTypes,

    data: null,
    saving: false,
    fetching: false,
    fetchingDetails: false,
    model: ut.deepClone(Counteragent),
    deleting: {},

    fetchingHistory: false,
    history: null,
  },

  getters: {
    getCounteragent: (state) => (id) => state.data?.results?.find(m => m.id === id),
    getType: (state) => (id) => CounteragentTypes.find(m => m.id === id),
    getClientType: (state) => (id) => ClientTypes.find(m => m.id === id),
  },

  mutations: {
    setItems(state, data) {
      state.data = data
    },

    setHistory(state, data) {
      state.history = data
    },

    setModel(state, data) {
      state.model = {...ut.deepClone(Counteragent), ...data}
    },
  },

  actions: {
    fetch({state, commit}, filters = {}) {
      filters.limit = filters.limit || 10000

      if (!state.fetching) {
        commit('setItems', null)
        state.fetching = CounteragentResource
                            .list(ut.objFilter(filters))
                            .then((response) => {
                              commit('setItems', response.body)
                            })
                            .finally(() => state.fetching = false)
      }

      return state.fetching
    },

    fetchHistory({state, commit}, filters = {}) {
      if (!state.fetchingHistory) {
        commit('setHistory', null)
        state.fetchingHistory = CounteragentResource
                                    .history({...ut.objFilter(filters), offset: (filters.limit || 0) * ((filters.page || 1) - 1)})
                                    .then((response) => {
                                      commit('setHistory', response.body)
                                    })
                                    .finally(() => state.fetchingHistory = false)
      }

      return state.fetchingHistory
    },

    show({state, commit}, id) {
      if (!state.fetchingDetails) {
        commit('setModel', {})
        state.fetchingDetails = CounteragentResource.details({id})
          .then(({body}) => {
            commit('setModel', body)
          })
          .finally(() => state.fetchingDetails = false)
      }

      return state.fetchingDetails
    },

    save({state, commit}) {
      state.saving = true
      let promise
      if (state.model.id) {
        promise = CounteragentResource.update({id: state.model.id}, state.model)
      } else {
        promise = CounteragentResource.create(state.model)
      }

      return promise
        .then(({body}) => {
          commit('setModel', body)
        })
        .finally(() => state.saving = false)
    },

    remove({state, dispatch}, id) {
      Vue.set(state.deleting, id, true)
      return CounteragentResource.remove({id})
        .then(() => dispatch('fetch'))
        .finally(() => Vue.set(state.deleting, id, false))
    },
  },
}
