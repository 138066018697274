<template>
  <v-card :loading="!!fetchingDetails">
    <v-card-title>
      Продажа #{{ model.id }} от {{ model.reg_date|stringDateTime }}
      <v-spacer></v-spacer>

      <consignment-note v-if="model.id && !compact" :consignment="model || {}"></consignment-note>

      <v-btn icon @click="$emit('dialog:close')">
        <v-icon color="error">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="7">
          <div>
            <b>Номер регистрации:</b> {{ model.reg_number }}
          </div>
          <div>
            <b>Контрагент:</b> {{ model.counteragent_full_name }} (ИНН: {{ model.counteragent_inn || '-'}})
          </div>
        </v-col>
        <v-col cols="5">
          <div><b>Водитель:</b> {{ model.non_staff_driver }}</div>
          <div><b>Транспорт:</b> {{ model.non_company_transport }}</div>
        </v-col>
      </v-row>
      <div class="text-body-1">
      </div>

      <h2 class="mt-4 mb-3 font-weight-regular text--primary">Элементы</h2>

      <v-row>
        <v-col cols="12">
          <v-data-table hide-default-footer
                        :headers="consignmentItemHeaders" :items="model.consignments_elements"
          >
            <template #item="{item, index}">
              <tr>
                <td class="text-center">{{ index + 1 }}</td>
                <td>{{item.product_type_name}}</td>
                <td>
                  {{(getMeasurement(item.product_type_measurement) || {}).name}}
                </td>
                <td class="text-center">{{ item.amount }}</td>
                <td>{{item.price|money(model.currency)}}</td>
                <td class="text-right">{{item.total|money(model.currency, true)}}</td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="6" class="d-flex align-end">
          <v-btn v-if="!compact" color="primary" dark rounded @click="$emit('dialog:edit', model)">
            <v-icon small left>mdi-pen</v-icon>
            Изменить
          </v-btn>
        </v-col>
        <v-col cols="6" class="text-right">
          <h4 class="mt-3">Итого:</h4>
          <span class="text-h4">{{total|money(model.currency)}}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ConsignmentNote from '@/modules/consignment/templates/consignment-note.vue'

import {mapGetters, createNamespacedHelpers} from 'vuex'
const {mapState} = createNamespacedHelpers('Consignment')

export default {
  name: 'consignment-details',

  props: {
    compact: {
      type: Boolean,
    }
  },

  data() {
    return {
      consignmentItemHeaders: [
        {text: '#', width: '70px'},
        {text: 'Продукт'},
        {text: 'Ед измерения'},
        {text: 'Кол-во', width: '120px', align: 'center'},
        {text: 'Цена', width: '150px'},
        {text: 'На сумму', width: '150px', align: 'right'},
      ],
    }
  },

  computed: {
    ...mapState(['fetchingDetails', 'model']),
    ...mapGetters('Product', ['getProduct']),
    ...mapGetters('CurrencyRate', ['getCurrency']),
    ...mapGetters('Measurement', ['getMeasurement']),

    total() {
      return (this.model.consignments_elements || [])
                .reduce((total, ti) => total + (ti.amount || 0) * (ti.price || 0) * (100 - (ti.discount_percent || 0)) / 100, 0)
                .toFixed(this.pricePrecision)
    },
    pricePrecision() {
      return this.getCurrency(this.model.currency).precision
    }
  },

  mounted() {
    this.$store.dispatch('Product/fetch')
  },

  components: {
    ConsignmentNote
  }
}
</script>
