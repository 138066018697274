<template>
  <v-card :loading="!!fetching">
    <v-card-title>
      Сырье #{{model.id}}
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('dialog:close')">
        <v-icon color="error">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text v-if="!fetching && model.id">
      <div>Название: {{model.name}}</div>
      <div>Код: {{model.id}}</div>
      <div>Ед измерения: {{model.measurement}}</div>
      <div>Цена покупки: {{model.buy_price}}</div>
      <div>
        Статус:
        <v-chip dark x-small :color="model.is_active ? 'success' : 'error'">
          {{model.is_active ? 'активный' : 'неактивный'}}
        </v-chip>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex'

  const {mapState} = createNamespacedHelpers('Material')

  export default {
    name: 'material-details',

    computed: {
      ...mapState(['fetching', 'model']),
    },
  }
</script>
