<template>
  <v-container fluid>
    <v-row ref="filtersContainer" class="mb-1">
      <v-col cols="8" class="d-flex align-center pb-0">
        <span class="text-h5">Список контрагентов</span>
        <v-dialog v-if="canCrud" v-model="formDialog" width="80vw" persistent>
          <template #activator="{on, attrs}">
            <v-btn class="ml-3" style="vertical-align: middle;" rounded color="primary" small
                   v-bind="attrs" v-on="on"
            >
              <v-icon left>mdi-plus</v-icon> Добавить
            </v-btn>
          </template>
          <counteragent-form v-if="formDialog"
                          @counteragent:form:saved="closeForm"
                          @counteragent:form:cancel="closeForm"
          ></counteragent-form>
        </v-dialog>
      </v-col>
      <v-col class="pb-0" cols="4">
        <v-text-field v-model="filter.full_name" :disabled="!!fetching"
                      label="Название" prepend-inner-icon="mdi-account-circle"
                      clearable hide-details ref="full_name_filter"
        ></v-text-field>
      </v-col>
      <v-col class="pb-0" cols="2">
        <v-autocomplete v-model="filter.region"
                        :items="regions" @change="filter.district = null"
                        item-value="id" item-text="title"
                        label="Область" hide-details clearable
        ></v-autocomplete>
      </v-col>
      <v-col class="pb-0" cols="2">
        <v-autocomplete v-model="filter.district"
                        :items="getDistricts(filter.region)"
                        item-value="id" item-text="title"
                        label="Район / Город" hide-details clearable
        ></v-autocomplete>
      </v-col>
      <v-col class="pb-0" cols="2">
        <v-autocomplete v-model="filter.group"
                        :items="groups"
                        item-value="id" item-text="name"
                        label="Группа" hide-details clearable
        ></v-autocomplete>
      </v-col>
      <v-col class="pb-0" cols="2">
        <v-text-field v-model="filter.inn" :disabled="!!fetching" type="number"
                      label="ИНН" prepend-inner-icon="mdi-identifier"
                      clearable hide-details ref="tin_filter"
        ></v-text-field>
      </v-col>
      <v-col v-if="canCrud" class="pb-0" cols="2">
        <v-autocomplete v-model="filter.agent"
                        :items="agents"
                        :loading="usersLoading"
                        item-value="id" item-text="name"
                        label="Агент" clearable hide-details
        ></v-autocomplete>
      </v-col>
      <v-col class="pb-0" cols="2">
        <v-text-field v-model="phoneFilter" :disabled="!!fetching"
                      label="Телефон" prepend-inner-icon="mdi-phone" autocomplete="off"
                      @click:clear="phoneFilter = ''" clearable hide-details  ref="phone_filter"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      :headers="nonSortableHeaders"
      :items="counteragents"
      :loading="!!fetching" :items-per-page="filter.limit"
      :height="dataTableHeight" disable-items-per-page hide-default-footer fixed-header
      no-data-text="Нет данных" loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td @click="showDetails(item)" class="text-center px-0">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td @click="showDetails(item)">
            {{item.full_name}}
            <v-chip class="ml-2" small v-if="getGroup(item.group)" dark :color="getColor(item.group)">{{(getGroup(item.group) || {}).name}}</v-chip>
          </td>
          <td @click="showDetails(item)">{{item.phone|phone}}</td>
          <td @click="showDetails(item)">{{item.inn}}</td>
          <td @click="showDetails(item)">{{item.address}}</td>
          <td class="text-right px-0">
            <v-btn icon small depressed color="success" @click="showDetails(item)">
              <v-icon small>mdi-eye</v-icon>
            </v-btn>
            <template v-if="canCrud">
              <v-btn class="ml-2" icon small depressed color="primary" dark @click="showForm(item)">
                <v-icon small>mdi-pen</v-icon>
              </v-btn>
              <v-btn class="ml-2" icon small depressed color="error" @click="askRemove(item)"
                     :loading="deleting[item.id]"
              ><v-icon small>mdi-delete</v-icon></v-btn>
            </template>
          </td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages"></pagination>

    <v-dialog v-if="detailDialog" fullscreen v-model="detailDialog" width="60vw" content-class="printable-dialog">
      <counteragent-details @dialog:close="closeDetails"
                            @dialog:edit="editForm"
      ></counteragent-details>
    </v-dialog>
  </v-container>
</template>

<script>
import {mapState as mapStateDefault, mapGetters as mapGettersDefault, createNamespacedHelpers} from 'vuex'
const {mapState, mapActions, mapMutations} = createNamespacedHelpers('Counteragent')

import CounteragentDetails from '@/modules/counteragent/details'
import CounteragentForm from '@/modules/counteragent/form'

import {hasFilter, hasDialog} from '@/mixins'

export default {
    name: 'counteragent-list',

    mixins: [hasFilter, hasDialog],

    provide() {
      return {
        canCrud: this.canCrud
      }
    },

    refocusable: {
      full_name: 'full_name_filter',
      inn: 'tin_filter',
      phone: 'phone_filter',
    },

    customFilters: {
      full_name: null,
      inn: null,
      phone: null,
      region: null,
      district: null,
    },

    data() {
      return {
        rawPhoneFilter: '',
      }
    },

    created() {
      if (this.$route.query.counteragent_id) {
        this.showCounteragentDetailsById(this.$route.query.counteragent_id)
      }

      this.fetch(this.filter)
    },

    methods: {
      ...mapActions(['fetch', 'show', 'remove']),
      ...mapMutations(['setModel']),

      editForm(item) {
        this.showForm(item)
        this.detailDialog = false
      },

      showForm(item) {
        this.show(item.id)
        this.formDialog = true
      },

      showCounteragentDetailsById(id) {
        this.show(id)
        this.detailDialog = true
      }
    },

  computed: {
    ...mapState([
      'fetching', 'deleting',
      'data',
      'CounteragentTypes',
    ]),
    ...mapGettersDefault('Group', ['getGroup', 'getColor']),
    ...mapStateDefault('Group', {
      groups: state => state.data?.results || [],
    }),

    ...mapStateDefault('Location', {
      regions: state => state.regions?.results || [],
    }),
    ...mapGettersDefault('Location', ['getDistricts']),

    ...mapGettersDefault('User', ['getUsersByRole']),
    ...mapStateDefault('User', {
      usersLoading: state => !!state.fetching
    }),
    agents() {
      return this.getUsersByRole('agent').map((item) => {
                  return {
                    id: item.id,
                    name: this.$options.filters.full_name(item),
                  }
                })
    },

    headers() {
      return [
        {text: '#', align: 'center', width: '70px'},
        {text: 'Название'},
        {text: 'Телефон', width: '170px'},
        {text: 'ИНН', width: '100px'},
        {text: 'Адрес', width: '200px'},
        {text: 'Действия', align: 'right', width: '120px', sortable: false},
      ]
    },

    counteragents() {
      return this.data?.results || []
    },

    phoneFilter: {
      get() {
        this.rawPhoneFilter
      },
      set(val) {
        this.rawPhoneFilter = val
        this.filter.phone = val?.toString().replace(/[^0-9]/g, '')
      }
    },

    canCrud() {
      return this.userService.hasRole('admin') || this.userService.can('contragent_add_button')
    }
  },

    mounted() {
      this.$store.dispatch('User/fetch')

      this.$eventBus.$emit('setTitle', 'Список контрагентов')
    },

    components: {
      CounteragentForm,
      CounteragentDetails,
    }
  }
</script>
