<template>
  <v-card :loading="!!fetchingConsignmentReturn">
    <v-card-title>
      Возврат #{{ consignmentReturn.id }} от {{consignmentReturn.reg_date | stringDateTime}}
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('dialog:close')">
        <v-icon color="error">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="7">
          <div>
            <b>Номер регистрации:</b> {{ consignmentReturn.reg_number }}
          </div>
          <div>
            <b>Контрагент:</b> {{consignmentReturn.counteragent_name}}
          </div>
          <div v-if="!fetchingConsignment">
            <b>Фактура:</b> {{consignment.reg_number}} от {{consignment.reg_date|date}}
          </div>
        </v-col>
      </v-row>

      <h2 class="mt-4 mb-3 font-weight-regular text--primary">Элементы</h2>

      <div v-if="!!fetchingConsignment" class="ma-4 text-center">
        <v-progress-linear color="primary" indeterminate/>
      </div>
      <v-row v-else>
        <v-col cols="12">
          <v-data-table hide-default-footer
                        :headers="consignmentItemHeaders"
                        :items="consignmentReturn.returned_products"
          >
            <template #item="{item, index}">
              <tr>
                <td class="text-center">{{ index + 1 }}</td>
                <td>{{ getConsigned(item.element).product_type_name || '' }}</td>
                <td class="text-center">{{ getConsigned(item.element).amount|prettyInt }}</td>
                <td class="text-center">{{item.amount|prettyInt}}</td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters, createNamespacedHelpers} from 'vuex'
const {mapState} = createNamespacedHelpers('Consignment')

export default {
  name: 'consignment-details',

  data() {
    return {
      consignmentItemHeaders: [
        {text: '#', width: '70px'},
        {text: 'Продукт'},
        {text: 'В фактуре', align: 'center'},
        {text: 'Из низ возвращено', align: 'center'},
      ],
    }
  },

  computed: {
    ...mapState(['fetchingConsignmentReturn', 'consignmentReturn', 'consignmentItems']),
    ...mapState({
      fetchingConsignment: 'fetchingDetails',
      consignment: 'model',
    }),
    ...mapGetters('Product', ['getProduct']),
  },

  methods: {
    getConsigned(productId) {
      return this.consignmentItems.find(e => e.id === productId) || {}
    }
  },
}
</script>
