<template>
  <v-container fluid>
    <v-row ref="filtersContainer">
      <v-col cols="6" class="d-flex align-center">
        <span class="text-h5">Список типов продукта</span>
        <v-dialog v-if="userService.can('product_type_add_button')" v-model="formDialog" width="500" persistent>
          <template #activator="{on, attrs}">
            <v-btn class="ml-3" style="vertical-align: middle;" rounded color="primary" small
                   v-bind="attrs" v-on="on"
            >
              <v-icon left>mdi-plus</v-icon> Добавить
            </v-btn>
          </template>
          <product-form v-if="formDialog"
                        @product:form:saved="closeForm"
                        @product:form:cancel="closeForm"
          ></product-form>
        </v-dialog>
      </v-col>
    </v-row>

    <v-data-table :headers="nonSortableHeaders"
                  :items="products"
                  :loading="!!fetching" :items-per-page="filter.limit"
                  :height="dataTableHeight" disable-items-per-page hide-default-footer fixed-header
                  no-data-text="Нет данных" loading-text="Данные загружаются"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{filter.limit * (filter.page - 1) + index + 1}}</td>
          <td>{{item.name}}</td>
          <td>{{(getMeasurement(item.measurement) || {}).name}}</td>
          <td>{{item.recipe_doc.name}}</td>
          <td class="text-right">{{item.sell_price|money}}</td>
          <td class="text-right">{{item.sell_price_usd|money('usd')}}</td>
          <td class="text-right">{{item.revinue_amount|money}}</td>
          <td class="text-right">{{item.job_volume_price|money}}</td>
          <td class="text-right">
            <v-btn class="ml-2" icon small depressed color="success" @click="showDetails(item)">
              <v-icon small>mdi-eye</v-icon>
            </v-btn>
            <v-btn class="ml-2" icon small depressed color="primary" dark @click="showForm(item)">
              <v-icon small>mdi-pen</v-icon>
            </v-btn>
            <v-btn class="ml-2" icon small depressed color="error" @click="askRemove(item)"
                   :loading="deleting[item.id]"
            >
              <v-icon left small>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages"></pagination>

    <v-dialog v-model="detailDialog" width="80vw" persistent>
      <product-details @dialog:close="closeDetails"
                       @dialog:edit="editForm"
      ></product-details>
    </v-dialog>
  </v-container>
</template>

<script>
import {createNamespacedHelpers, mapGetters} from 'vuex'

  const {mapState, mapActions, mapMutations} = createNamespacedHelpers('Product')

  import ProductDetails from '@/modules/product/details'
  import ProductForm from '@/modules/product/form'

  import {hasDialog, hasFilter} from '@/mixins'

  export default {
    name: 'product-list',

    mixins: [hasDialog, hasFilter],

    customFilters: {
      name: '',
    },

    created() {
      this.fetch(this.filter)
    },

    computed: {
      ...mapState(['data', 'fetching', 'deleting']),
      ...mapGetters('Measurement', ['getMeasurement']),

      headers() {
        return [
          {text: '#', align: 'center', width: '80'},
          {text: 'Название'},
          {text: 'Ед'},
          {text: 'Рецепт'},
          {text: 'Цена', width: '140px', align: 'right'},
          {text: 'В валюте', width: '100px', align: 'right'},
          {text: 'Маржа', width: '140px', align: 'right'},
          {text: 'Цена работы', width: '140px', align: 'right'},
          {text: 'Действия', width: '150px', align: 'right', sortable: false},
        ]
      },

      products() {
        return this.data?.results || []
      },
    },

    methods: {
      ...mapActions(['fetch', 'show', 'remove']),
      ...mapMutations(['setModel']),

      editForm(item) {
        this.showForm(item)
        this.detailDialog = false
      },

      showForm(item) {
        this.formDialog = true
        this.setModel(item)
      },
    },

    mounted() {
      this.$eventBus.$emit('setTitle', 'Список типов продукта')
      this.$store.dispatch('Recipe/fetch')
      this.$store.dispatch('Measurement/fetch')
    },

    components: {
      ProductForm,
      ProductDetails,
    }
  }
</script>
