import Vue from 'vue'
import ut from '@/utils'
import {SERVER_DATE_FORMAT} from '@/filters'
import moment from 'moment'

import {Resource} from 'vue-resource'
import {Currencies} from './currency-rates'

const Consignment = {
  id: null,
  reg_number: '',
  reg_date: moment().format(SERVER_DATE_FORMAT),
  counteragent: '',
  consignments_elements: [],
  currency: Currencies[0].id,
  non_staff_driver: '',
  non_company_transport: '',
}

export const ConsignmentItem = {
  id: null,
  product_type: null,
  amount: 1,
  price: '',
  total: 0,
}

const ConsignmentReturn = {
  id: null,
  reg_date: moment().format(SERVER_DATE_FORMAT),
  counteragent: null,
  doc: null,
  consignments_elements: [],
  returned_products: [],
}

const ConsignmentResource = new Resource('/', {}, {
  list: {
    method: 'GET',
    url: 'warehouse/consignmentdoc/list/',
  },
  details: {
    method: 'GET',
    url: 'warehouse/consignmentdoc/detail/{id}/',
  },
  create: {
    method: 'POST',
    url: 'warehouse/consignmentdoc/create/',
  },
  update: {
    method: 'PUT',
    url: 'warehouse/consignmentdoc/update/{id}/',
  },
  remove: {
    method: 'DELETE',
    url: 'warehouse/consignmentdoc/delete/{id}/',
  },
})

const ConsignmentElementResource = new Resource('/', {}, {
  list: {
    method: 'GET',
    url: 'warehouse/consignment-element/list/',
  },
})

const ConsignmentReturnResource = new Resource('/', {}, {
  list: {
    method: 'GET',
    url: 'warehouse/return-product-doc/list/',
  },
  create: {
    method: 'POST',
    url: 'warehouse/return-product-doc/create/',
  },
  details: {
    method: 'GET',
    url: 'warehouse/return-product-doc/detail/{id}/',
  },
  update: {
    method: 'PUT',
    url: 'warehouse/return-product-doc/detail/{id}/',
  },
  remove: {
    method: 'DELETE',
    url: 'warehouse/return-product-doc/delete/{id}/',
  },
})

export default {
  namespaced: true,

  state: {
    data: null,

    fetching: false,
    fetchingDetails: false,
    model: ut.deepClone(Consignment),
    consignmentItems: [],
    saving: false,
    deleting: {},

    fetchingConsignmentElements: false,
    consignmentElements: null,

    fetchingConsignmentReturns: false,
    fetchingConsignmentReturn: false,
    consignmentReturns: null,
    savingReturn: false,
    consignmentReturn: ut.deepClone(ConsignmentReturn),
  },

  mutations: {
    setItems(state, data) {
      state.data = data
    },

    updateModel(state, data) {
      state.model = {...state.model, ...data}
    },

    setModel(state, data) {
      state.model = {...ut.deepClone(Consignment), ...data}

      if (state.model.counteragent && ('object' === typeof state.model.counteragent)) {
        state.model.counteragent_full_name = state.model.counteragent.full_name
        state.model.counteragent_inn = state.model.counteragent.inn
        state.model.counteragent = state.model.counteragent.id
      }
    },

    setConsignmentItems(state, items) {
      state.consignmentItems = items
      state.consignmentItems.map(el => {
        if (!el.total) el.total = el.amount * el.price
        if (el.product_type && ('object' === typeof el.product_type)) {
          el.product_type_measurement = el.product_type.measurement
          el.product_type_name = el.product_type.name
          el.product_type = el.product_type.id
        }
      })
    },

    createItem(state, data = {}) {
      state.consignmentItems.push({...ut.deepClone(ConsignmentItem), ...data})
    },

    setItem(state, {index, item}) {
      Vue.set(state.consignmentItems, index, item)
    },

    clearItem(state, index) {
      state.consignmentItems.splice(index,1)
    },

    setElements(state, data) {
      state.consignmentElements = data
    },

    setReturns(state, data) {
      state.consignmentReturns = data
    },

    setReturn(state, data) {
      state.consignmentReturn = data
    },
    updateReturn(state, data) {
      state.consignmentReturn = {...state.consignmentReturn, ...data}
    }
  },

  actions: {
    fetch({state, commit}, filters = {}) {
      filters.limit = filters.limit || 1000

      if (!state.fetching) {
        commit('setItems', null)
        state.fetching = ConsignmentResource.list(ut.objFilter(filters))
          .then((response) => {
            commit('setItems', response.body)
          })
          .finally(() => state.fetching = false)
      }

      return state.fetching
    },

    show({state, commit}, id) {
      if (!state.fetchingDetails) {
        commit('setModel', {})
        state.fetchingDetails = ConsignmentResource.details({id})
                                    .then(({body}) => {
                                      commit('setModel', body)
                                      commit('setConsignmentItems', body.consignments_elements)
                                    })
                                    .finally(() => state.fetchingDetails = false)
      }

      return state.fetchingDetails
    },

    save({state, commit}) {
      state.saving = true

      state.model.consignments_elements = state.consignmentItems.map(ri => ut.objFilter(ri, [
        'reg_date', 'reg_number',
        'counteragent_name', 'product_type_name',
      ]))

      let promise
      if (state.model.id) {
        promise = ConsignmentResource.update({id: state.model.id}, state.model)
      } else {
        promise = ConsignmentResource.create(state.model)
      }

      return new Promise((resolve, reject) => {
        promise.then(() => {
          resolve()
          commit('setModel', {})
          commit('setConsignmentItems', [])
        })
        .catch(({body}) => Vue.$eventBus.$emit('notification:error', body.detail))
        .finally(() => state.saving = false)
      })
    },

    remove({state, dispatch}, id) {
      Vue.set(state.deleting, id, true)
      return ConsignmentResource.remove({id})
        .then(() => dispatch('fetch'))
        .catch(({body}) => Vue.$eventBus.$emit('notification:error', body.detail))
        .finally(() => Vue.set(state.deleting, id, false))
    },

    removeItem({state, dispatch}, index) {
      state.consignmentItems.splice(index, 1)
    },

    fetchElements({state, commit}, filters = {}) {
      filters.limit = filters.limit || 1000

      if (!state.fetchingConsignmentElements) {
        commit('setElements', null)
        state.fetchingConsignmentElements = ConsignmentElementResource.list(ut.objFilter(filters))
          .then((response) => {
            commit('setElements', response.body)
          })
          .finally(() => state.fetchingConsignmentElements = false)
      }

      return state.fetchingConsignmentElements
    },

    fetchReturns({state, commit}, filters = {}) {
      filters.limit = filters.limit || 1000

      if (!state.fetchingConsignmentReturns) {
        commit('setReturns', null)
        state.fetchingConsignmentReturns = ConsignmentReturnResource.list(ut.objFilter(filters))
                                                      .then((response) => {
                                                        commit('setReturns', response.body)
                                                      })
                                                      .finally(() => state.fetchingConsignmentReturns = false)
      }

      return state.fetchingConsignmentReturns
    },

    showReturn({state, commit}, id) {
      if (!state.fetchingConsignmentReturn) {
        commit('setReturn', {})
        state.fetchingConsignmentReturn = ConsignmentReturnResource.details({id})
                                              .then(({body}) => {
                                                commit('setReturn', body)
                                              })
                                              .finally(() => state.fetchingConsignmentReturn = false)
      }

      return state.fetchingConsignmentReturn
    },


    generateReturnItems({state, commit, dispatch}, id) {
      return dispatch('show', id)
                .then(() => {
                  commit('updateReturn', {
                    consignments_elements: state.consignmentItems.map(ci => {
                      return {
                        id: ci.id,
                        product_name: ci.product_type_name,
                        amount: 0,
                        max_amount: ci.amount,
                      }
                    }),
                  })
                })
    },

    saveReturns({state, commit}) {
      commit('updateReturn', {
        consignments_elements: state.consignmentReturn.consignments_elements
                                    .filter(e => e.amount > 0)
                                    .map(e => {
                                      return {
                                        id: e.id,
                                        amount: e.amount
                                      }
                                    })
      })

      if (!state.consignmentReturn.consignments_elements.length) {
        return Promise.resolve()
      }

      state.savingReturn = true
      let promise
      if (state.consignmentReturn.id) {
        promise = ConsignmentReturnResource.update({id: state.consignmentReturn.id}, state.consignmentReturn)
      } else {
        promise = ConsignmentReturnResource.create(state.consignmentReturn)
      }

      return promise.then(() => commit('setReturn', {}))
              .catch(({body}) => Vue.$eventBus.$emit('notification:error', body.detail))
              .finally(() => state.savingReturn = false)
    },

    removeReturn({state, dispatch}, id) {
      Vue.set(state.deleting, id, true)
      return ConsignmentReturnResource.remove({id})
                  .then(() => dispatch('fetchReturns'))
                  .catch(({body}) => Vue.$eventBus.$emit('notification:error', body.detail))
                  .finally(() => Vue.set(state.deleting, id, false))
    },
  },
}
