<template>
  <v-form>
    <validation-observer ref="observer" v-slot="{invalid, reset, handleSubmit}">
      <v-card>
        <v-card-title>{{model.id ? 'Изменить тип продукта' : 'Создать тип продукта'}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <validation-provider v-slot="{errors}" name="name" rules="required|max:256">
                <v-text-field v-model="model.name"
                              required :error-messages="errors"
                              label="Название"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="9">
              <validation-provider v-slot="{errors}" name="recipe_doc" rules="required|integer">
                <v-select v-model="model.recipe_doc"
                          :items="recipes"
                          item-value="id" item-text="name"
                          required :error-messages="errors"
                          label="Рецепты"
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col cols="3">
              <validation-provider v-slot="{errors}" name="measurement" rules="required|integer">
                <v-select v-model="model.measurement"
                          :items="measurements"
                          item-value="id" item-text="name"
                          required :error-messages="errors"
                          label="Ед измерения"
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{errors}" name="sell_price" rules="required|integer|positive">
                <v-text-field v-model="model.sell_price"
                              required :error-messages="errors"
                              label="Цена продажи" type="number" suffix="сум"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{errors}" name="sell_price_usd" rules="required|double|positive">
                <v-text-field v-model="model.sell_price_usd"
                              required :error-messages="errors"
                              label="Цена продажи в валюте" suffix="$"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{errors}" name="revinue_amount" rules="required|integer|positive">
                <v-text-field v-model="model.revinue_amount"
                              required :error-messages="errors"
                              label="Маржа" type="number" suffix="сум"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{errors}" name="job_volume_price" rules="double">
                <v-text-field v-model="model.job_volume_price"
                              required :error-messages="errors"
                              label="Цена работы" suffix="сум"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-space-between pb-4 px-4">
          <v-btn @click="handleSubmit(save)"
                 :loading="!!saving"
                 :disabled="!!saving || invalid"
                 rounded color="success"
          ><v-icon left>mdi-content-save</v-icon> {{model.id ? 'Сохранить' : 'Создать'}}</v-btn>
          <v-btn @click="cancel(reset)"
                 :disabled="!!saving"
                 :loading="!!saving"
                 rounded text color="error"
          ><v-icon left>mdi-cancel</v-icon> Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-form>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: 'product-form',

    computed: {
      ...mapState('Measurement', {
        measurements: state => state.data?.results || []
      }),

      ...mapState('Product', ['saving', 'model']),

      ...mapState('Recipe', {
        recipes: state => state.data?.results || []
      }),
    },

    methods: {
      cancel(reset) {
        reset()
        this.$emit('product:form:cancel')
      },

      save() {
        this.$store
          .dispatch('Product/save')
          .then(() => {
            this.$emit('product:form:saved')
          })
      },
    },
  }
</script>
