var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
var handleSubmit = ref.handleSubmit;
return [_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.model.id ? 'Изменить сырье' : 'Создать сырье'))]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"label":"Название"},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{attrs:{"name":"measurement","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.measurements,"item-value":"id","item-text":"name","required":"","error-messages":errors,"label":"Ед измерения"},model:{value:(_vm.model.measurement),callback:function ($$v) {_vm.$set(_vm.model, "measurement", $$v)},expression:"model.measurement"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"buy_price","rules":"required|double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"label":"Цена в суммах","suffix":"сум"},model:{value:(_vm.model.buy_price),callback:function ($$v) {_vm.$set(_vm.model, "buy_price", $$v)},expression:"model.buy_price"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"buy_price","rules":"required|double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"label":"Цена в долларах","suffix":"$"},model:{value:(_vm.model.buy_price_usd),callback:function ($$v) {_vm.$set(_vm.model, "buy_price_usd", $$v)},expression:"model.buy_price_usd"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":"min_notified_amount","rules":"double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","error-messages":errors,"label":"Оповестить если остаток ниже ..."},model:{value:(_vm.model.min_notified_amount),callback:function ($$v) {_vm.$set(_vm.model, "min_notified_amount", $$v)},expression:"model.min_notified_amount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-switch',{attrs:{"required":"","label":"Активный"},model:{value:(_vm.model.is_active),callback:function ($$v) {_vm.$set(_vm.model, "is_active", $$v)},expression:"model.is_active"}})],1)],1)],1),_c('v-card-actions',{staticClass:"justify-space-between pb-4 px-4"},[_c('v-btn',{attrs:{"loading":!!_vm.saving,"disabled":!!_vm.saving || invalid,"rounded":"","color":"success"},on:{"click":function($event){return handleSubmit(_vm.save)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.model.id ? 'Сохранить' : 'Создать'))],1),_c('v-btn',{attrs:{"disabled":!!_vm.saving,"loading":!!_vm.saving,"rounded":"","text":"","color":"error"},on:{"click":function($event){return _vm.cancel(reset)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cancel")]),_vm._v(" Отмена")],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }