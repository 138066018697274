<template>
  <v-card :loading="!!fetching">
    <v-card-title>
      Тип продукта #{{model.id}}
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('dialog:close')">
        <v-icon color="error">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="6">
          <div>Название: {{model.name}}</div>
          <div>Рецепт: {{model.recipe_doc_name}}</div>
          <div>Ед измерения: {{(getMeasurement(model.measurement) || {}).full_name}}</div>
        </v-col>
        <v-col cols="6">
          <div>Описание: {{model.recipe_doc_description}}</div>
        </v-col>
      </v-row>
      <v-row no-gutters class="font-weight-bold v-card__title px-0 pb-0">
        <v-col cols="6" class="px-0">
          <span class="purple--text text--darken-3">
            Цена в сумах: {{model.sell_price|money}}
          </span>
          •
          <span class="indigo--text text--darken-2">
            Цена в валюте: {{model.sell_price_usd|money('usd')}}
          </span>
        </v-col>
        <v-col cols="6" class="px-0 text-right">
          <span>Цена работы {{model.job_volume_price|money}}</span>
          •
          <span class="success--text">Маржа {{model.revinue_amount|money}}</span>
        </v-col>
      </v-row>
      <h2 class="mt-4 mb-3 font-weight-regular text--primary">Материалы ({{(model.recipe_doc_elements || []).length}})</h2>
      <v-row>
        <v-col cols="12">
          <v-data-table hide-default-footer disable-items-per-page :items-per-page="1000"
                        :headers="itemHeaders" :items="model.recipe_doc_elements"
          >
            <template #item="{item, index}">
              <tr>
                <td class="text-center">{{index + 1}}</td>
                <td>{{item.raw_material.name}}</td>
                <td class="text-right">{{item.amount|prettyInt}} {{getMeasurement(item.raw_material.measurement).name}}</td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import {mapGetters, createNamespacedHelpers} from 'vuex'

  const {mapState} = createNamespacedHelpers('Product')

  export default {
    name: 'product-details',

    computed: {
      ...mapState(['fetching', 'model']),
      itemHeaders() {
        return [
          {text: '#', width: '70px', align: 'center'},
          {text: 'Название', width: '300px'},
          {text: 'Кол-во', width: '120px', align: 'right'},
        ]
      },
      ...mapGetters('Measurement', ['getMeasurement']),
    },

    mounted() {
      this.$store.dispatch('Measurement/fetch')
    }
  }
</script>
