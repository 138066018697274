import Vue from 'vue'
import '@/assets/css/global.css'

Vue.config.productionTip = false

import Http from '@/router/http'
Vue.use(Http)

import {filterInstaller} from './filters'
Vue.use(filterInstaller)

import vuetify from '@/plugins/vuetify'

import extensionsInstaller from './extensions'
Vue.use(extensionsInstaller)

import store from '@/store'
import router from '@/router'

import Validation from '@/validation'
Vue.use(Validation)

import VuetifyMask from 'vuetify-mask'
Vue.use(VuetifyMask)

import App from './App.vue'
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
