<template>
    <v-card :loading="!!fetchingDetails">
      <div ref="counteragentDetails">
        <v-card-title>
          Контрагент #{{model.id}} • Агент: {{(getUser(model.agent) || {name: '-'}) |full_name}}
          <v-btn color="primary" dark small text @click="$emit('dialog:edit', model)" class="ml-4 hide-on-print">
            <v-icon small left>mdi-pen</v-icon> Изменить
          </v-btn>
          <v-btn color="primary" text @click="print" class="ml-4 hide-on-print">
            <v-icon left>mdi-printer</v-icon> Печать
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn v-if="false" small outlined color="success" class="mr-4 hide-on-print" @click="showStartingPointDialog">
            <v-icon small left color="success">mdi-calendar-start</v-icon> установить начальный баланс
          </v-btn>
          <v-btn icon @click="$emit('dialog:close')">
            <v-icon color="error">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="text-body-1">
          <table class="w-100">
            <tr>
              <td class="font-weight-bold w-20">Название:</td>
              <td class="w-30">{{clientType ? clientType + ' - ' : ''}} {{model.full_name}} ({{counteragentType}})
                <v-chip small dark :color="getColor(model.group)" v-if="model.group">{{getGroup(model.group).name}}</v-chip>
              </td>
              <td class="w-20 font-weight-bold">E-mail:</td>
              <td class="w-30">{{model.email}}</td>
            </tr>

            <tr>
              <td class="font-weight-bold w-20">ИНН:</td>
              <td class="w-30">{{model.inn}}</td>
              <td class="font-weight-bold w-20">Телефон 1:</td>
              <td class="w-30">{{model.phone|phone}}</td>
            </tr>

            <tr>
              <td class="font-weight-bold w-20">Код:</td>
              <td class="w-30">{{model.code}}</td>
              <td class="font-weight-bold w-20">Телефон 2:</td>
              <td class="w-30">{{model.phone_2|phone}}</td>
            </tr>

            <tr>
              <td class="font-weight-bold w-20">МФО:</td>
              <td class="w-30">{{model.bank_code}}</td>
              <td class="font-weight-bold w-20">Телефон 3:</td>
              <td class="w-30">{{model.phone_3|phone}}</td>
            </tr>
            <tr>
              <td class="font-weight-bold w-20">Рассчетный счет:</td>
              <td class="w-30">{{model.bank_account}}</td>
              <td class="font-weight-bold w-20">Телефон 4:</td>
              <td class="w-30">{{model.phone_4|phone}}</td>
            </tr>
            <tr>
              <td class="w-20 font-weight-bold pt-4">Локация:</td>
              <td :colspan="canCrud ? 3 : 1" class="w-30 pt-4">{{(getRegion(model.region) || {}).title}}, {{(getDistrict(model.district) || {}).title}}</td>
              <template v-if="!canCrud">
                <td class="w-20 font-weight-bold pt-4">Агент:</td>
                <td class="w-30 pt-4">
                  {{(getUser(model.agent) || {name: '-'})|full_name}}
                </td>
              </template>
            </tr>

            <tr>
              <td class="w-20 font-weight-bold">Адрес:</td>
              <td class="w-30">{{model.address}}</td>
              <td class="w-20 font-weight-bold">Комментарий к адресу:</td>
              <td class="w-30">{{model.comment_on_address}}</td>
            </tr>
            <tr>
              <td class="w-20 font-weight-bold">Юридический адрес:</td>
              <td class="w-30">{{model.legal_address}}</td>
              <td class="w-20 font-weight-bold">Комментарий к адресу:</td>
              <td class="w-30">{{model.comment_on_legal_address}}</td>
            </tr>
          </table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <div class="mt-2">{{model.comment}}</div>

          <counteragent-history v-if="!fetchingDetails" :counteragent-id="model.id"></counteragent-history>
        </v-card-text>

        <v-dialog v-if="startingBalanceDialog" v-model="startingBalanceDialog" width="60vw">
          <starting-point-form @starting-point:form:cancel="closeStartingFormDialog"
                               @starting-point:form:saved="closeStartingFormDialog"
          ></starting-point-form>
        </v-dialog>
      </div>
    </v-card>
</template>

<script>
  import {mapState, mapGetters} from 'vuex'
  import CounteragentHistory from '@/modules/counteragent/history'
  import StartingPointForm from '@/modules/payment/starting-point/form.vue'

  export default {
    name: 'counteragent-details',

    inject: ['canCrud'],

    data() {
      return {
        contractHeaders: [
          {text: '#', align: 'center', width: '70px'},
          {text: 'Код', width: '100px'},
          {text: 'Рег номер', width: '120px'},
          {text: 'Сумма', width: '200px'},
          {text: 'Комментарий'},
        ],

        startingBalanceDialog: false,
      }
    },

    computed: {
      ...mapState('Counteragent', ['fetchingDetails', 'model']),
      ...mapGetters('Counteragent', ['getType', 'getClientType']),
      ...mapGetters('Group', ['getGroup', 'getColor']),
      ...mapGetters('Location', ['getDistrict', 'getRegion']),
      ...mapGetters('User', ['getUser']),

      counteragentType() {
        return this.getType(this.model.counteragent_type)?.name || ''
      },
      clientType() {
        return this.getClientType(this.model.client_type)?.name || ''
      }
    },

    methods: {
      showStartingPointDialog() {
        this.$store.commit('StartingPoint/setModel', {counteragent: this.model.id})
        this.startingBalanceDialog = true
      },

      closeStartingFormDialog() {
        this.startingBalanceDialog = false
        this.$eventBus.$emit('counteragent:refresh-history')
      },

      print() {
          let w = window.open()
          w.document.write(
            '<style>@media print {body { margin: 0; } table { width: 100%; border-collapse: collapse; } th, td { border: 1px solid black; padding: 8px; } .hide-on-print { display: none; }  tr:nth-child(even) { background-color: #f2f2f2;}} </style>'
            + this.$refs.counteragentDetails.innerHTML
          )
          w.focus()
          w.print()
          w.close()
      }
    },

    components: {
      StartingPointForm,
      CounteragentHistory,
    }
  }
</script>

<style>
.w-100 {
  width: 100%;
}
.w-30 {
  width: 35%;
}
.w-20 {
  width: 15%;
}
</style>

